import React from 'react';
import { IconProps } from 'types';

const TiktokIcon = ({ color, className }: IconProps) => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        fill={`${color ? color : '#171717'}`}
      />
      <path
        d="M19.7593 11.0065C18.8328 10.4797 18.1643 9.63656 17.9557 8.65427C17.9107 8.4421 17.8856 8.22352 17.8856 8H14.9287L14.9237 18.3348C14.8743 19.4923 13.782 20.421 12.443 20.421C12.0268 20.421 11.635 20.3305 11.2899 20.1719C10.4986 19.809 9.95695 19.0857 9.95695 18.2536C9.95695 17.0583 11.0723 16.0856 12.4427 16.0856C12.6986 16.0856 12.9439 16.1226 13.1759 16.1857V13.5532C12.9356 13.5244 12.6916 13.5069 12.4427 13.5069C9.44165 13.5069 7 15.6359 7 18.2536C7 19.8593 7.91979 21.2805 9.32384 22.1397C10.2076 22.6813 11.2832 23 12.443 23C15.4443 23 17.8856 20.8707 17.8856 18.2536V13.0127C19.0454 13.7386 20.4665 14.1664 22 14.1664V11.5875C21.174 11.5875 20.4047 11.3735 19.7593 11.0065Z"
        fill={`${color ? '#101010' : 'white'}`}
      />
    </svg>
  );
};

export default TiktokIcon;
