import React from 'react';
import { IconProps } from 'types';

const InstagramIcon = ({ color, className }: IconProps) => {
  return (
    <svg
      className={className}
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7626 18.1971C17.3609 18.1971 18.6565 16.9015 18.6565 15.3033C18.6565 13.705 17.3609 12.4094 15.7626 12.4094C14.1644 12.4094 12.8688 13.705 12.8688 15.3033C12.8688 16.9015 14.1644 18.1971 15.7626 18.1971Z"
        fill={`${color ? color : '#171717'}`}
      />
      <path
        d="M22.5234 10.198C22.3717 9.79366 22.1695 9.50302 21.8789 9.19973C21.5756 8.89644 21.285 8.70689 20.8806 8.55525C20.5773 8.44152 20.1224 8.30251 19.2757 8.25196C18.3658 8.21405 18.0878 8.20142 15.7753 8.20142C13.4627 8.20142 13.1847 8.20142 12.2748 8.25196C11.4282 8.28987 10.9733 8.42888 10.67 8.55525C10.2656 8.70689 9.97494 8.90908 9.67165 9.19973C9.36836 9.50302 9.17881 9.79366 9.02717 10.198C8.91344 10.5013 8.77443 10.9563 8.72388 11.8029C8.68597 12.7128 8.67333 12.9908 8.67333 15.3034C8.67333 17.6159 8.67334 17.8939 8.72388 18.8038C8.76179 19.6504 8.9008 20.1054 9.02717 20.4087C9.17881 20.813 9.381 21.1037 9.67165 21.407C9.97494 21.7103 10.2656 21.8998 10.67 22.0515C10.9733 22.1652 11.4282 22.3042 12.2748 22.3547C13.1847 22.3927 13.4627 22.4053 15.7753 22.4053C18.0878 22.4053 18.3658 22.4053 19.2757 22.3547C20.1224 22.3168 20.5773 22.1778 20.8806 22.0515C21.285 21.8998 21.5756 21.6976 21.8789 21.407C22.1822 21.1037 22.3717 20.813 22.5234 20.4087C22.6371 20.1054 22.7761 19.6504 22.8267 18.8038C22.8646 17.8939 22.8772 17.6159 22.8772 15.3034C22.8772 12.9908 22.8772 12.7128 22.8267 11.8029C22.7887 10.9563 22.6371 10.5013 22.5234 10.198ZM15.7626 19.7642C13.2984 19.7642 11.3144 17.7675 11.3144 15.316C11.3144 12.8644 13.3111 10.8678 15.7626 10.8678C18.2268 10.8678 20.2108 12.8644 20.2108 15.316C20.2108 17.7675 18.2142 19.7642 15.7626 19.7642ZM20.3877 11.7145C19.8064 11.7145 19.3515 11.2469 19.3515 10.6782C19.3515 10.0969 19.8191 9.64202 20.3877 9.64202C20.969 9.64202 21.424 10.1096 21.424 10.6782C21.4366 11.2595 20.969 11.7145 20.3877 11.7145Z"
        fill={`${color ? color : '#171717'}`}
      />
      <path
        d="M15.75 0C7.46019 0 0.75 6.71019 0.75 15C0.75 23.2898 7.46019 30 15.75 30C24.0398 30 30.75 23.2898 30.75 15C30.75 6.71019 24.0272 0 15.75 0ZM24.381 18.8795C24.3431 19.802 24.1914 20.4339 23.9766 20.9899C23.7492 21.5585 23.4585 22.0514 22.9783 22.5316C22.4981 23.0118 22.0179 23.3151 21.4366 23.5299C20.8806 23.7447 20.2487 23.8837 19.3262 23.9343C18.4037 23.9722 18.1131 23.9848 15.75 23.9848C13.3869 23.9848 13.0963 23.9722 12.1738 23.9343C11.2513 23.8964 10.6194 23.7447 10.0634 23.5299C9.49473 23.3024 9.0019 23.0118 8.52169 22.5316C8.04149 22.0514 7.73821 21.5712 7.52338 20.9899C7.30855 20.4339 7.16954 19.802 7.119 18.8795C7.08109 17.957 7.06845 17.6664 7.06845 15.3033C7.06845 12.9402 7.08109 12.6495 7.119 11.727C7.15691 10.8046 7.30855 10.1727 7.52338 9.61668C7.75084 9.04802 8.04149 8.55518 8.52169 8.07498C9.0019 7.59478 9.4821 7.29149 10.0634 7.07666C10.6194 6.86184 11.2513 6.72283 12.1738 6.67228C13.0963 6.63437 13.3869 6.62174 15.75 6.62174C18.1131 6.62174 18.4037 6.63437 19.3262 6.67228C20.2487 6.71019 20.8806 6.86184 21.4366 7.07666C22.0053 7.30413 22.4981 7.59478 22.9783 8.07498C23.4585 8.55518 23.7618 9.03538 23.9766 9.61668C24.1914 10.1727 24.3305 10.8046 24.381 11.727C24.4316 12.6495 24.4315 12.9402 24.4315 15.3033C24.4315 17.6664 24.4189 17.957 24.381 18.8795Z"
        fill={`${color ? color : '#171717'}`}
      />
    </svg>
  );
};

export default InstagramIcon;
