import React from 'react';
import { IconProps } from 'types';

const Logo = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="125"
      height="42"
      viewBox="0 0 586.58 196.91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {`.logo-fill {
                    fill: #fff;
                }`}
        </style>
      </defs>
      <path
        className="logo-fill"
        d="M586.58,113a11,11,0,1,1-11-10.94,10.86,10.86,0,0,1,5.48,1.46,11,11,0,0,1,5.48,9.48Zm-2.12,0a8.84,8.84,0,1,0-2.59,6.24A8.47,8.47,0,0,0,584.46,113Zm-13.8-5.85h5.23a4.73,4.73,0,0,1,3.22.9,3.09,3.09,0,0,1,1,2.4,3,3,0,0,1-.76,2,4.45,4.45,0,0,1-2.39,1.25,3.17,3.17,0,0,1,1,.54,5.23,5.23,0,0,1,.93,1.19l1.89,3.38h-3.44a26.33,26.33,0,0,0-2.25-4,1.59,1.59,0,0,0-1.15-.65,1.86,1.86,0,0,0-.36,0v4.6h-2.91v-11.7Zm2.91,5h1.26a3,3,0,0,0,1.78-.41,1.34,1.34,0,0,0,0-2.1,2.7,2.7,0,0,0-1.69-.41h-1.38v2.93Z"
      />
      <polygon
        className="logo-fill"
        points="75.92 81.91 75.92 90.84 75.92 98.01 75.94 98.01 75.94 73.48 75.92 73.48 75.92 81.91"
      />
      <polygon
        className="logo-fill"
        points="75.92 98.01 75.92 123.3 75.98 123.3 75.99 98.01 75.94 98.01 75.92 98.01"
      />
      <polygon
        className="logo-fill"
        points="75.94 73.48 75.94 73.48 75.99 73.48 75.99 46.1 75.92 46.1 75.92 73.48 75.94 73.48"
      />
      <polygon
        className="logo-fill"
        points="75.94 90.84 75.94 81.9 75.99 81.9 75.99 73.48 75.94 73.48 75.94 98.01 75.99 98.01 75.99 90.84 75.94 90.84"
      />
      <rect
        className="logo-fill"
        x="75.99"
        y="73.48"
        width="0.08"
        height="8.42"
      />
      <rect
        className="logo-fill"
        x="75.99"
        y="90.84"
        width="0.08"
        height="7.17"
      />
      <polygon
        className="logo-fill"
        points="116.91 78.08 122.36 73.98 127.86 77.92 125.89 71.43 131.31 67.18 124.4 67.18 122.36 61.16 120.38 67.18 113.61 67.18 119.04 71.43 116.91 78.08"
      />
      <path
        className="logo-fill"
        d="M152,59.57V40.32h-5.25V32.5H5v8H0V59.79H5V92.05H0V111.3H5v13.34l27.58,21.15-3.19,4.12,15.27,11.75,3.19-4.16L75.5,178.73l.2.13.21.15,28.17-21.8,3.26,4.23,15.25-11.73-3.22-4.2,27.33-20.86V111.09H152V91.87H146.7V59.59H152ZM23.27,78.21l2.12-6.68L20,67.3h6.76l2-6,2.05,6h6.89l-5.41,4.23,2,6.53-5.49-4ZM76,98l0,25.28H59.36l-2.55,12.79-26.58,0,24-90H76V73.48h6.85l7.65,28.61H82L81.23,98Zm63.69,23.26-18.57,14.16.17.65h-1L76.15,169.82V123.3h16l2.55,12.79h25.63l.8-.65L97.25,46.1H76.16v-7h63.53Z"
      />
      <rect
        className="logo-fill"
        x="76.08"
        y="90.84"
        width="0.07"
        height="7.17"
      />
      <polygon
        className="logo-fill"
        points="76.08 73.48 76.08 81.9 76.15 81.9 76.15 73.48 75.99 73.48 75.99 73.48 76.08 73.48"
      />
      <polygon
        className="logo-fill"
        points="121.28 136.09 121.11 135.44 120.32 136.09 121.28 136.09"
      />
      <polygon
        className="logo-fill"
        points="80.48 90.83 78.11 81.9 76.15 81.9 76.15 90.84 77.84 90.83 80.48 90.83"
      />
      <rect
        className="logo-fill"
        x="75.94"
        y="81.9"
        width="0.05"
        height="8.94"
      />
      <rect
        className="logo-fill"
        x="75.99"
        y="81.9"
        width="0.08"
        height="8.94"
      />
      <rect
        className="logo-fill"
        x="76.08"
        y="81.9"
        width="0.07"
        height="8.94"
      />
      <polygon
        className="logo-fill"
        points="74.09 90.83 71.44 90.83 73.83 81.9 75.92 81.91 75.92 73.48 75.51 73.48 69.17 73.48 61.51 102.09 69.98 102.09 70.8 98.01 75.51 98.01 75.92 98.01 75.92 90.84 74.09 90.83"
      />
      <path
        className="logo-fill"
        d="M120.79,26.65h25.92V.45H120.79v19.6H108.11V.45H82.19v19.6H69.5V.45H43.58v19.6H30.88V.45H5v26.2Zm10.84-16.28,2-6,2,6h6.91l-5.42,4.26,2,6.5-5.49-3.95-5.44,4.11,2.11-6.65-5.42-4.26h6.76Zm-38.57.37,2-6,2,6H104L98.58,15l2,6.5L95,17.55l-5.43,4.12L91.73,15l-5.42-4.26h6.75ZM54.5,10.39l2-6,2,6h6.93L60,14.63l2,6.52-5.48-4L51.05,21.3l2.1-6.66L47.74,10.4H54.5ZM17.91,17.18,12.46,21.3l2.12-6.66L9.17,10.4h6.76l2-6,2,6h6.9l-5.41,4.24,2,6.52-5.49-4Z"
      />
      <path
        className="logo-fill"
        d="M205.51,76.31l-4.64,12.9H176.73L212.05,0h17.72l35,89.21H240.34l-4.27-12.9H205.51Zm6.19-18.09h18.44l-9-30.51h-.35l-9,30.51Z"
      />
      <path className="logo-fill" d="M273,0h19.72V89.21H273Z" />
      <path
        className="logo-fill"
        d="M307,22.31h18.58v7.34h.33c4-5.07,10.47-9.65,20.61-9.65,23.41,0,32.21,20.85,32.21,36.41,0,19.3-13.3,35.08-32.11,35.08-11.91,0-17.48-5.77-19.88-8.32v28.34H307V22.31Zm52,33.52c0-8-5.73-17.13-16.56-17.13s-16.54,9.16-16.54,17.13c0,8.43,6.2,17,16.54,17S359,64.26,359,55.83Z"
      />
      <path
        className="logo-fill"
        d="M409.61,15.18H389.87V0h19.74Zm0,74H389.87V22.31h19.74Z"
      />
      <path
        className="logo-fill"
        d="M423.88,22.31h18.46v7.11h.24c2-3.26,6.19-9.42,18-9.42,6.25,0,13.3,2.67,16.85,5.8s7.66,8.43,7.66,21.56V89.21H465.3V52.69c0-4.71,0-13.87-10.34-13.87-11.29,0-11.29,9.89-11.29,14v36.4H423.89V22.31Z"
      />
      <path
        className="logo-fill"
        d="M562.71,68.24a39.3,39.3,0,0,1-13.41,17.1,31.9,31.9,0,0,1-18.82,6,35.28,35.28,0,0,1-35-35.43C495.5,37.25,510.05,20,530.15,20S565,36.14,565,56.53a28.5,28.5,0,0,1-.47,5.3H515c1.16,8,7.7,12.66,15.44,12.66,6.09,0,9.28-2.78,12-6.27h20.19Zm-17.49-19.9c-.78-4-4.73-11.45-15-11.45s-14.19,7.48-15,11.45Z"
      />
      <path
        className="logo-fill"
        d="M218.12,170.42H197.87L195.06,180H176.85l21.7-57.72H218L239.7,180H221l-2.89-9.53Zm-3.7-12.48-6.37-20.75-6.31,20.75Z"
      />
      <path
        className="logo-fill"
        d="M242.44,138.13h15V145q2.16-4.46,4.47-6.12a9.4,9.4,0,0,1,5.69-1.67,17.29,17.29,0,0,1,7.76,2.21l-5,11.42a12.56,12.56,0,0,0-4.49-1.18,5.53,5.53,0,0,0-4.88,2.6q-2.47,3.66-2.48,13.7v14h-16.1V138.14Z"
      />
      <path
        className="logo-fill"
        d="M280.19,138.13h15v6.1a20.75,20.75,0,0,1,6.53-5.43,18,18,0,0,1,7.95-1.61,15.25,15.25,0,0,1,7.93,1.77,13.09,13.09,0,0,1,4.77,5.28,24.09,24.09,0,0,1,6.88-5.57,17.87,17.87,0,0,1,7.67-1.48q6.72,0,10.5,4t3.78,12.5V180H335.09V156.13a6.71,6.71,0,0,0-1.1-4.21,4.84,4.84,0,0,0-4-2.17,5.65,5.65,0,0,0-4.55,2.05q-1.72,2.05-1.73,6.58V180H307.64v-23a14.41,14.41,0,0,0-.32-3.74,5.09,5.09,0,0,0-1.78-2.54,4.75,4.75,0,0,0-3-1,5.7,5.7,0,0,0-4.54,2.09c-1.19,1.39-1.78,3.68-1.78,6.85V180H280.2V138.14Z"
      />
      <path
        className="logo-fill"
        d="M356.73,159.16a20.9,20.9,0,0,1,6.46-15.77q6.45-6.19,17.44-6.2,12.56,0,19,7.28a21.1,21.1,0,0,1,5.16,14.45q0,9.65-6.4,15.81t-17.7,6.16q-10.08,0-16.3-5.12a20.57,20.57,0,0,1-7.64-16.62Zm16.07,0c0,3.73.75,6.48,2.26,8.27a7.42,7.42,0,0,0,11.38,0q2.22-2.64,2.22-8.47,0-5.43-2.24-8.09a7,7,0,0,0-5.55-2.66,7.25,7.25,0,0,0-5.79,2.7c-1.52,1.8-2.28,4.53-2.28,8.21Z"
      />
      <path
        className="logo-fill"
        d="M410.62,138.13h15V145q2.16-4.46,4.47-6.12a9.4,9.4,0,0,1,5.69-1.67,17.29,17.29,0,0,1,7.76,2.21l-5,11.42a12.56,12.56,0,0,0-4.49-1.18,5.53,5.53,0,0,0-4.88,2.6q-2.47,3.66-2.48,13.7v14h-16.1V138.14Z"
      />
      <path
        className="logo-fill"
        d="M444.83,122.23h16v10.91h-16Zm0,15.91h16V180h-16V138.14Z"
      />
      <path
        className="logo-fill"
        d="M469.08,138.13H484v6.81a22.37,22.37,0,0,1,6.77-6,17.89,17.89,0,0,1,8.35-1.79q6.66,0,10.42,4t3.76,12.23V180H497.19V157q0-3.93-1.46-5.57a5.2,5.2,0,0,0-4.1-1.63,5.81,5.81,0,0,0-4.72,2.21q-1.82,2.21-1.81,7.91V180h-16V138.13Z"
      />
      <path
        className="logo-fill"
        d="M550,138.13h15v39.49l0,1.85a17.54,17.54,0,0,1-1.67,7.5,15.81,15.81,0,0,1-4.45,5.77,17.52,17.52,0,0,1-7,3.19,43.63,43.63,0,0,1-9.79,1q-12.6,0-17.31-3.78A12.31,12.31,0,0,1,520,183c0-.53,0-1.23.08-2.13l15.55,1.77a5.06,5.06,0,0,0,1.81,3,7.68,7.68,0,0,0,4.45,1.22,6.74,6.74,0,0,0,5.18-1.85c1.14-1.23,1.71-3.39,1.71-6.46v-6.34a15.57,15.57,0,0,1-12.71,6q-8.39,0-13.55-7.32-3.66-5.21-3.66-13.74,0-9.77,4.73-14.88A16,16,0,0,1,536,137.18a17.36,17.36,0,0,1,8,1.65,17.62,17.62,0,0,1,5.93,5.47v-6.18Zm-15.08,20.28q0,4.53,1.93,6.71a6.48,6.48,0,0,0,5.08,2.19,6.31,6.31,0,0,0,5-2.26q2-2.27,2-6.83c0-3-.71-5.38-2.13-7a6.68,6.68,0,0,0-5.2-2.42,6,6,0,0,0-4.9,2.22q-1.83,2.22-1.83,7.38Z"
      />
    </svg>
  );
};

export default Logo;
